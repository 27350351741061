import React from 'react';
import {
  BILogger,
  IOwnerBILoggerContext,
  IVisitorBILoggerContext,
} from 'yoshi-flow-editor-runtime';
import { Logger as uouLogger } from '@wix/bi-logger-wixboost-ugc';
import { Logger as userLogger } from '@wix/bi-logger-wixboost-users';

// https://stackoverflow.com/questions/63961803/eslint-says-all-enums-in-typescript-app-are-already-declared-in-the-upper-scope
// eslint-disable-next-line no-shadow
export enum LoggerTypes {
  SETTINGS = 'SETTINGS',
  WIDGET = 'WIDGET',
}

export const OwnerBIContext = React.createContext<IOwnerBILoggerContext | null>(
  null,
);

export const VisitorBIContext =
  React.createContext<IVisitorBILoggerContext | null>(null);

const createTemporaryLoggerMock = <T extends {}>(): T => {
  const functionNames = [
    ...Object.keys(uouLogger.prototype),
    ...Object.keys(userLogger.prototype),
  ];

  const logger = functionNames.reduce(
    (functions, functionName) => ({ ...functions, [functionName]: () => {} }),
    {} as any,
  );
  logger.util = { updateDefaults: () => {} };

  return logger;
};

const BILoggerProvider =
  (loggerType: LoggerTypes) =>
  <WrappedComponentProps extends any>({
    children,
  }: {
    children: React.ReactElement<WrappedComponentProps>;
  }) => {
    return (
      // <BILoggerDefaults defaults={biDefaults}>
      <BILogger
        owner={loggerType === LoggerTypes.SETTINGS}
        visitor={loggerType === LoggerTypes.WIDGET}
      >
        {(logger: IOwnerBILoggerContext | IVisitorBILoggerContext) => {
          return loggerType === LoggerTypes.SETTINGS ? (
            <OwnerBIContext.Provider value={logger as IOwnerBILoggerContext}>
              {children}
            </OwnerBIContext.Provider>
          ) : (
            <VisitorBIContext.Provider
              value={logger as IVisitorBILoggerContext}
            >
              {children}
            </VisitorBIContext.Provider>
          );
        }}
      </BILogger>
      // </BILoggerDefaults>
    );
  };

export const SettingsBILoggerProvider = BILoggerProvider(LoggerTypes.SETTINGS);
export const WidgetBILoggerProvider = BILoggerProvider(LoggerTypes.WIDGET);

export const useOwnerBILogger = (): IOwnerBILoggerContext =>
  React.useContext(OwnerBIContext) as IOwnerBILoggerContext;

export const useVisitorBILogger = (): IVisitorBILoggerContext =>
  React.useContext(VisitorBIContext) as IVisitorBILoggerContext;
